import React, { useState, useEffect } from 'react';
import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';
import kycPdfUrl from './../../Documents/KYC.pdf'; // Adjust the path to your PDF file
import bondAbsaPdfUrl from './../../Documents/BondAbsa.pdf'; // Adjust the path to your PDF file
import BondAbsa from '../../PDFComponents/BondAbsa';
import Kyc from '../../PDFComponents/Kyc';

const PreviewCase = () => {
 

  useEffect(() => {
    handleModifyAndCombinePdfs();
  }, []);

  const fetchPdf = async () => {
    const existingPdfBytes = await fetch(kycPdfUrl).then(res => res.arrayBuffer());
    return existingPdfBytes;
  };

  const fetchBondAbsaPdf = async () => {
    const existingPdfBytes = await fetch(bondAbsaPdfUrl).then(res => res.arrayBuffer());
    return existingPdfBytes;
  };

  





  const combinePdfs = async (pdfs) => {
    const combinedPdf = await PDFDocument.create();

    for (const pdfBytes of pdfs) {
      const pdfDoc = await PDFDocument.load(pdfBytes);
      const copiedPages = await combinedPdf.copyPages(pdfDoc, pdfDoc.getPageIndices());
      copiedPages.forEach(page => combinedPdf.addPage(page));
    }

    const combinedPdfBytes = await combinedPdf.save();
    return combinedPdfBytes;
  };





  const handleModifyAndCombinePdfs = async () => {
   
   
    // later on make this to be conditional by passing in the type of pdf you want
    const kycpdfBytes = await fetchPdf();
    const bondAbsapdfBytes = await fetchBondAbsaPdf();
    // later on make this to be conditional by passing in the type of pdf you want





    // Define details for each individual
    const individual1 = {
      firstPageDetails: 'Details',
      secondPageDetails: 'Details',
      thirdPageDetails: 'Details',
    };

    const individual2 = {
      firstPageDetails: 'Details',
      secondPageDetails: 'Details',
      thirdPageDetails: 'Details',
    };

    // Modify PDFs for each individual
    const modifiedPdfBytes = await BondAbsa(bondAbsapdfBytes, individual1);
    const modifiedPdf1Bytes = await Kyc(kycpdfBytes, individual1);
    const modifiedPdf2Bytes = await Kyc(kycpdfBytes, individual2);

    // Combine the modified PDFs
    const combinedPdfBytes = await combinePdfs([modifiedPdf1Bytes, modifiedPdf2Bytes,modifiedPdfBytes]);

    // Create a URL for the combined PDF
    const blob = new Blob([combinedPdfBytes], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);

    // Open the combined PDF in a new tab
    window.open(url, '_blank');
  };





  return null; // The component doesn't need to render anything in the DOM
};

export default PreviewCase;
