import { PDFDocument, rgb, StandardFonts } from 'pdf-lib';

const Kyc = async (pdfBytes, individualDetails) => {
    const pdfDoc = await PDFDocument.load(pdfBytes);
    const font = await pdfDoc.embedFont(StandardFonts.Helvetica);
    // Modify each page with individual details
//////////////////////////////////KYC KYC KYC//////////////////////////////////////////////
    if (individualDetails.firstPageDetails) {
      const firstPage = pdfDoc.getPage(0);
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103,
        y: 546,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 25,
        y: 546 - 20,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 15,
        y: 546 - 40 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 45,
        y: 546 - 59 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 40,
        y: 546 -78 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 45,
        y: 546 - 98 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 30,
        y: 546 - 118 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 55,
        y: 546 - 198 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 70,
        y: 546 - 218 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 70,
        y: 546 - 236 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 70,
        y: 546 - 258 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 70,
        y: 546 - 278 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 70,
        y: 546 - 298 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 75,
        y: 546 - 318 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 75,
        y: 546 - 339 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 75,
        y: 546 - 358 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 75,
        y: 546 - 379 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 110,
        y: 546 - 400 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 260,
        y: 546 - 400 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 315,
        y: 546 - 359 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      firstPage.drawText(individualDetails.firstPageDetails, {
        x: 103 + 265,
        y: 546 - 319 ,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
    }
    if (individualDetails.secondPageDetails) {
      const secondPage = pdfDoc.getPage(1);
      secondPage.drawText(individualDetails.secondPageDetails, {
        x: 142,
        y: 536,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x: 363,
        y: 536,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x: 363,
        y: 515.5,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x:170,
        y: 515.5,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x:140,
        y: 495,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x:168,
        y: 428.8,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
      secondPage.drawText(individualDetails.secondPageDetails, {
        x:175,
        y: 409,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
// this part here is for the documents submitted
      secondPage.drawText(individualDetails.secondPageDetails, {
        x:175,
        y: 409,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });
// this part here is for the documents submitted
    }
    if (individualDetails.thirdPageDetails) {
      const thirdPage = pdfDoc.getPage(2);
      thirdPage.drawText(individualDetails.thirdPageDetails, {
        x:130,
        y: 439,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });

      thirdPage.drawText(individualDetails.thirdPageDetails, {
        x:110,
        y: 412,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });

      thirdPage.drawText(individualDetails.thirdPageDetails, {
        x: 100,
        y: 385.5,
        size: 12,
        font,
        color: rgb(0, 0, 0),
      });

    }
//////////////////////////////////KYC KYC KYC/////////////////////////////////////////////
    const modifiedPdfBytes = await pdfDoc.save();
    return modifiedPdfBytes;
  };

export default Kyc